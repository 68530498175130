footer {
  position: fixed;
  z-index: 15;
  bottom: 0;
  background-color: $dark;
  left: 0;
  right: 0;
  padding-bottom: 10px;
  height: 72px; }

.block-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .adresse {
    display: flex;
    color: $primary;
    min-height: 80px; } }

@include media-breakpoint-down(md) {
  footer {
    position: relative;
    z-index: 15;
    bottom: 0;
    background-color: $dark;
    left: 0;
    right: 0;
    padding-bottom: 10px; }

  .block-footer {
    flex-direction: column;
    .adresse {
      flex-direction: column; } } }

@include media-breakpoint-down(sm) {
  footer {
    height: 170px; } }
