body {
  font-family: MaisonNeue, sans-serif;
  font-weight: 300;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased; }

small {
  font-size: 75%;
  line-height: 0.1rem; }

h1, h2, h3, h4, h5 {
  font-weight: 300; }

h1 {
  font-size: 3.125rem;
  color: $primary;
  position: relative;
  margin-left: 80px;
  letter-spacing: 4px;
  &:before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: $secondary;
    left: -80px;
    bottom: 13px;
    width: 68px; } }

h2 {
  letter-spacing: 3.8px;
  font-size: 2.8125rem; }

h2, h3, h4 {
  color: $white;
  &.style-h1 {
    color: $primary;
    position: relative;
    margin-left: 80px;
    letter-spacing: 4px;
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      background-color: $secondary;
      left: -80px;
      bottom: 13px;
      width: 68px; } } }

h4 {
  font-size: 1rem; }

h5 {
  font-size: 0.75rem;
  color: $brownish-gray;
  text-transform: uppercase; }

a {
  color: $secondary; }

footer {
  font-size: 0.6875rem;
  color: $primary; }

.lang {
  a {
    color: $white;
    text-transform: uppercase; } }

.black {
  color: $black;
  font-size: 1.125rem; }

.secondary {
  color: $secondary; }

.workshop {
  top: 50px;
  right: 80px;
  transition: all 0.3s ease-out;
  i {
    margin-left: 0;
    transition: all 0.3s ease-out; } }

hr {
  border-color: $secondary; }

.ft-size-10 {
  font-size: 1rem; }

.ft-size-24 {
  font-size: 1.5rem;
  a {
    color: $primary; } }

.lineheight-lg {
  line-height: 2.2; }

.underline {
  text-decoration: underline; }

.formulaire {
  display: flex;
  justify-content: space-around;
  .bloc2 {
    textarea {
      height: 361px;
      max-height: 361px; } } }

@include media-breakpoint-down(md) {
  h1 {
    font-size: 1.8rem;
    margin-left: 45px;
    &.display-4 {
      font-size: 1.5rem; }
    &:before {
      width: 30px;
      left: -40px;
      bottom: 10px; } }

  h2 {
    font-size: 1.35rem; }

  h3 {
    font-size: 1.2rem; }

  a {
    &.btn-menu {
      font-size: 1.2rem; }

    &.menu-footer {
      font-size: 1rem; } }

  p {
    &.black {
      font-size: 1rem; }

    &.lineheight-lg {
      line-height: 1.8; } }

  .ft-size-sm-10 {
    font-size: 0.625rem; }

  .ft-size-24 {
    font-size: 1rem; }

  .workshop {
    top: 20px;
    right: 20px; } }
