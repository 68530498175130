a {
  &:focus {
    outline: none; } }

.btn {
  vertical-align: bottom;
  padding-top: 0.8rem; }

.btn-sm {
  padding-top: 0.45rem; }

.btn-picto {
  padding: {
    top: 0.55rem;
    right: 0.7rem;
    left: 0.7rem; }
  font-size: 1.15rem; }

.hover-white {
  &:hover {
    color: $white !important; } }

.btn-picto-footer {
  font-size: 1rem !important;
  color: $picto;
  border-color: $picto; }


.btn-link {
  text-transform: uppercase;
  color: $secondary;
  cursor: pointer;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: $secondary;
    left: -33px;
    bottom: 3px;
    width: 23px;
    transition: width 0.2s, left 0.2s; } }

.btn {
  &.btn-link {
    color: $light;
    padding: 10px 15px;
    &:before {
      display: none; } }
  &.btn-all {
    color: $secondary;
    &:after {
      margin-left: 5px; }
    &:hover {
      color: $secondary !important; } } }

.btn-link-center {
  display: flex;
  justify-content: center;
  .btn-link {
    &:before {
      bottom: -4px; } } }

.collection {
  .btn-link {
    float: right;
    &:before {
      width: 0px; }
    &:after {
      margin-left: 5px;
      font-size: 12px; } } }

.btn-menu {
  color: $white;
  cursor: pointer;
  position: relative;
  font-size: 36px;
  margin-left: 45%;

  &:before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: #747474;
    left: -25px;
    bottom: 17px;
    width: 15px;
    transition: width 0.2s, left 0.2s; }

  &.active {
    color: $primary;

    &:before {
      background-color: $primary; } } }

[class*="btn-outline-"] {
  text-transform: uppercase; }

.nav-close {
  margin-bottom: 100px; }

.picto-footer {
  border: $primary 1px solid;
  padding: 5px;
  color: $primary;
  font-size: 20px; }

.btn-fleche-right {
  &:after {
    @extend [class^="icon-"];
    @extend .icon-xla-right-arrow:before;
    color: $white; } }

.btn-fleche-left {
  font-size: 12px;
  display: flex;
  align-items: center;
  &:before {
    @extend [class^="icon-"];
    @extend .icon-xla-left-arrow:before;
    color: $white;
    margin-right: 10px;
    font-size: 24px; } }

.project-listing {
  &.btn-fleche-left {
    display: flex;
    justify-content: flex-end; } }

.project-fiche {
  .btn-fleche-left {
    justify-content: center; } }

.btn-collection {
  img {
    opacity: 1;
    transition: all 0.5s ease; }
  .btn-right {
    display: flex;
    top: 10px;
    left: -70px;
    transition: 0.5s ease-in-out;
    i {
      opacity: 0;
      transition: opacity 0.5s ease;
      margin-left: 5px; } }

  .btn-left {
    display: flex;
    align-items: center;
    top: 10px;
    right: -70px;
    z-index: 10;
    transition: 0.5s ease-in-out;
    i {
      opacity: 0;
      transition: opacity 0.5s ease;
      margin-right: 15px; } }

  p {
    color: $primary; } }

.btn-valid {
  display: flex;
  justify-content: center; }

.btn-project {
  img {
    opacity: 1;
    transition: all 0.2s ease-in-out; } }

.btn-line {
  transition: all 0.33s ease-in-out;
  i[class^="icon-"] {
    font-size: 2rem; }
  .text-unstyled {
    text-transform: none; }
  .btn-outline-primary {
    display: none; } }

@include media-breakpoint-down(lg) {
  .menu {
    height: 1366px;
    .btn-menu {
      margin-bottom: 0; }

    .menu-footer {
      margin-bottom: 0; } } }

@include media-breakpoint-down(md) {
  .btn-collection {
    .btn-right {
      left: 16px;
      color: $white;
      i {
        opacity: 0; } }

    .btn-left {
      left: 15px;
      color: $white;
      max-width: 100%;
      i {
        opacity: 0; } } }

  .menu {
    .btn-menu {
      margin-bottom: 10px;
      &:before {
        bottom: 10px; } }

    .menu-footer {
      margin-bottom: 10px;
      &:first-of-type {
        margin-top: 50px; } } } }

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    width: 100%; }

  .menu {
    .btn-menu {
      margin-left: 40%;
      margin-bottom: 0;
      &:before {
        bottom: 10px; } }

    .menu-footer {
      margin-left: 40%;
      margin-bottom: 0; } } }
