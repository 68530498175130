// Spacer
$spacer: 1rem;
$spacers: (0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3), 6: ($spacer * 6));

// Colors
$black:         #000;
$brownish-gray: #666666;
$dark:          $black;
$light:         $brownish-gray;
$primary:       #a1a1a1;
$secondary:     #dc0b14;
$gray-border:   #333333;
$product:       #ebebeb;
$picto:         #4d4d4d;
$c-footer:      #7b7b7b;
$product-light: #dedede;

// Body
$body-bg:       $black;
$body-color:    $primary;

// Radius
$enable-rounded:        false;

// Forms
$input-bg:                  #0d0d0d;
$input-color:               #8c8c8c;
$input-border-color:        transparent;
$input-focus-border-color:  $gray-border;
$enable-shadows:            false;
$input-btn-focus-box-shadow: none;

$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;
$fs-3:                        $font-size-base * 3;
$fs-2:                        $font-size-base * 2;

$breadcrumb-bg:       transparent;
$breadcrumb-divider:  ">";

// Button
$input-btn-padding-y:         0.65rem;
$input-btn-padding-x:         1.3rem;
$btn-font-weight:             500;

// Fonts
$font-path:         "/frontend/fonts";
$icomoon-font-path: $font-path;

$slick-font-family: 'inherit';
