body {
  padding-bottom: 74px;
  scroll-behavior: smooth; }

header {
  height: 3.5rem; }

ul {
  li {
    &.active {
      border-bottom: none; } } }

.scroll-to {
  > p {
    &:after {
      content: "";
      position: absolute;
      z-index: 10;
      height: 0;
      top: -120px;
      left: -96px;
      width: 1px;
      background-color: $secondary;
      transition: height 1s ease-in-out; } } }

.border-bottom {
  border-bottom: $white 1px solid;
  transition: all 0.2s ease-in-out; }

.border-bottom-light {
  border-bottom: $light 1px solid;
  transition: all 0.2s ease-in-out; }

.border-light {
  border: $light 1px solid; }

.msg-error {
  color: $secondary; }

.error {
  border: $secondary 1px solid;
  color: $secondary; }

.project-fiche {
  .redline {
    display: block;
    z-index: 10;
    height: 110px;
    width: 1px;
    left: 50%;
    top: -130px;
    background-color: $secondary; } }

.connexion {
  .redline {
    display: block;
    z-index: 10;
    height: 180px;
    width: 1px;
    left: 50%;
    top: 125px;
    background-color: $secondary; } }

.icon-xla-combined-shape {
  font-size: 28px; }

.bg-product {
  background: $product; }

.bg-product-light {
  background-color: $product-light; }

.brownish-gray {
  color: $brownish-gray; }

.fs-3 {
  font-size: $fs-3; }

.fs-2 {
  font-size: $fs-2; }

.bg-img {
  background-image: url(/frontend/images/bg-hp.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }


.overflow {
  overflow: hidden; }

.c-footer {
  color: $c-footer; }

.pre-line {
  white-space: pre-line; }

.icon-before:before {
  color: $brownish-gray !important; }

.fiche {
 color: $black;
 text-decoration: underline; }


.position-relative {
  h2.position-absolute {
    font-size: 2.6rem !important;
    letter-spacing: 3px !important; } }

.btn-outline-secondary:hover {
  color: $white !important; }

@include media-breakpoint-down(md) {
  body {
    padding-bottom: 0; }

  .connexion {
    .red {
      display: none; } }

  .scroll-to {
    > p {
      &:after {
        height: 0;
        top: -90px;
        left: 3px; } } }

  .project-fiche {
    .redline {
      height: 50px;
      top: -70px; } }

  .connexion {
    .redline {
      display: none; } }

  .map {
    height: 700px; }

  .fs-3 {
    font-size: $h4-font-size; }

  .mb-sm-0-p {
    p {
      margin-bottom: 0 !important; } } }
