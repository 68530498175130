@font-face {
  font-family: MaisonNeue;
  src: url('#{$font-path}/MaisonNeue-Medium.eot');
  src: url('#{$font-path}/MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'), url('#{$font-path}/MaisonNeue-Medium.woff2') format('woff2'), url('#{$font-path}/MaisonNeue-Medium.woff') format('woff'), url('#{$font-path}/MaisonNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: MaisonNeue;
  src: url('#{$font-path}/MaisonNeue-Light.eot');
  src: url('#{$font-path}/MaisonNeue-Light.eot?#iefix') format('embedded-opentype'), url('#{$font-path}/MaisonNeue-Light.woff2') format('woff2'), url('#{$font-path}/MaisonNeue-Light.woff') format('woff'), url('#{$font-path}/MaisonNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }
