/* PX/REM mixin
  S'utilise comme ceci :
  @include rem(font-size, 16px)
  @include rem(letter-spacing, 4px)
  Possibilité de rajouter !important
  @include rem(font-size, 16px, !important) */
@function parseInt($n) {
  @return $n / ($n * 0 + 1); }

@mixin rem($property, $values, $content: null) {
  $px : ();
  $rem: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value); }

    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / 10 + rem)); }

      @if $unit == "rem" {
        $px : append($px,  ($val * 10 + px));
        $rem: append($rem, $value); } } }

  @if $px == $rem {
    #{$property}: $px $content; }
  @else {
    #{$property}: $px $content;
    #{$property}: $rem $content; } }

// Image cover
// Penser à ce que le parent ait une position relative et un overflow hidden
// S'utilise comme ceci : +img-cover
@mixin img-cover {
  bottom: -10000%;
  height: auto;
  left: -10000%;
  margin: auto;
  min-height: 1000%;
  min-width: 1000%;
  overflow: hidden;
  position: absolute;
  right: -10000%;
  top: -10000%;
  transform: scale(.1);
  width: auto; }

// Mixin pseudo-elements
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos; }

// Mixin truncate
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin vertical-truncate($line-height, $lines-to-show: 4) {
  display: -webkit-box;
  height: $line-height * $lines-to-show;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

// Margins/paddings mixin
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100);

@mixin generate-margin-bottom {
  @each $space in $spaceamounts {
    .mb-#{$space}-px {
      margin-bottom: #{$space}px; } } }

@mixin generate-margin-right {
  @each $space in $spaceamounts {
    .mr-#{$space}-px {
      margin-right: #{$space}px; } } }

@mixin generate-margin-top {
  @each $space in $spaceamounts {
    .mt-#{$space}-px {
      margin-top: #{$space}px; } } }

@mixin generate-margin-left {
  @each $space in $spaceamounts {
    .ml-#{$space}-px {
      margin-left: #{$space}px; } } }

@mixin generate-padding-top {
  @each $space in $spaceamounts {
    .pt-#{$space}-px {
      padding-top: #{$space}px; } } }

@mixin generate-padding-bottom {
  @each $space in $spaceamounts {
    .pb-#{$space}-px {
      padding-bottom: #{$space}px; } } }

@mixin generate-padding-right {
  @each $space in $spaceamounts {
    .pr-#{$space}-px {
      padding-right: #{$space}px; } } }

@mixin generate-padding-left {
  @each $space in $spaceamounts {
    .pl-#{$space}-px {
      padding-left: #{$space}px; } } }

@include generate-margin-bottom;
@include generate-margin-right;
@include generate-margin-left;
@include generate-margin-top;
@include generate-padding-bottom;
@include generate-padding-right;
@include generate-padding-left;
@include generate-padding-top;
