.navbar {
  max-height: 72px;
  padding: 0 1rem;
  .navbar-brand {
    position: absolute;
    left: calc((100vw - 80px) / 2);
    top: 20px; }

  .menu {
    background-image: url("/frontend/images/menu-xla.gif");
    background-repeat: no-repeat;
    background-color: $black;
    background-position-x: center;
    width: 100vw;
    height: 100vh;
    overflow: {
      y: scroll;
      x: hidden; }
    z-index: 3;
    position: absolute;
    padding-top: 17px;
    padding-bottom: 50px;
    -webkit-font-smoothing: antialiased;
    top: 0;
    left: 0;
    transition: left 0.6s ease-in-out;

    &.hidden {
      left: -100%; } }

  .menu-footer {
    color: $white;
    font-size: 1.25rem;
    margin-left: 45%;
    &:hover {
      color: $secondary; } }

  #nav-toggle {
    padding-top: 18px; }

  border-bottom: $primary 1px solid; }

.d-block {
  display: table !important; }

@include media-breakpoint-down(md) {
  .navbar {
    .navbar-brand {
      left: calc((100vw - 57px) / 2);
      img {
        width: 57px;
        height: 57px; } }

    .nav-close {
      margin-bottom: 60px; } } }
