a {
  &:hover {
    text-decoration: none; } }

.workshop {
  &:hover {
    right: 60px;

    i {
      margin-left: 20px; } } }

.lang {
  a {
    &.active {
      text-decoration: underline; } } }

.ft-size-24 {
  a {
    &:hover {
      color: $secondary; } } }
