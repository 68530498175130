[class*="btn-outline-"] {
  &:hover {
    color: $body-bg; } }

.btn-link {
  &:hover {
    color: $secondary;
    text-decoration: none;

    &:before {
      width: 35px;
      left: -45px; } } }

.btn {
  &.btn-link {
    &:hover {
      color: $white; }
    &.active {
      border: $light 1px solid; } } }

.collection {
  .btn-link {
    &:hover {
      color: $secondary;
      text-decoration: none;
      &:before {
        width: 0px;
        left: 0px; } } } }

.btn-menu {
  &:not(.active):hover {
    color: $secondary;
    text-decoration: none;

    &:before {
      width: 50px;
      left: -60px;
      background-color: $secondary; } } }

#nav-toggle, #nav-toggle-close {
  cursor: pointer; }

.picto-footer {
  &:hover {
    color: $secondary; } }

.btn-fleche-right {
  &:hover {
    &:after {
      color: $secondary; } } }

.btn-fleche-left {
  &:hover {
    color: $secondary;
    &:before {
      color: $secondary; } } }

.btn-collection {
  &:hover {
    img {
      transform: scale(1.05); }
    .btn-right {
      left: 10px;
      color: $secondary;
      i {
        opacity: 1; } }

    .btn-left {
      right: 10px;
      color: $secondary;
      i {
        opacity: 1; } } } }

.btn-project {
  &:hover {
    img {
      transform: scale(1.025); }
    .text-white {
      color: $secondary !important; }
    .border-bottom {
      border-bottom: $secondary 1px solid; } } }

.btn-outline-light {
  &:hover {
    color: $white; } }

.news-list {
  .btn-outline-light {
    &:hover {
      color: $dark; } } }

.btn-line:hover {
  flex: 0 0 87.33333%;
  max-width: 87.33333%;
  background-color: $product;
  .btn-outline-primary {
    display: block;
    border: 1px solid $body-bg;
    &:hover {
      background-color: $product;
      cursor: pointer; } } }

@include media-breakpoint-down(md) {
  .btn-collection {
    &:hover {
      .btn-right {
          left: 10px;
          color: $white;
          i {
            opacity: 0; } }

      .btn-left {
          left: -48px;
          color: $white;
          i {
            opacity: 0; } } } } }
