.article {
  .article-header {
    height: 270px;
    display: flex;
    justify-content: center; }

  .description {
    color: $primary;
    font-size: 0.75rem;
    line-height: 0.95rem;
    @include vertical-truncate(0.95rem, 4);
    a {
      color: $primary !important; } } }

