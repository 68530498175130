@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?8lfany');
  src:  url('#{$icomoon-font-path}/icomoon.eot?8lfany#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.woff2?8lfany') format('woff2'),
    url('#{$icomoon-font-path}/icomoon.ttf?8lfany') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?8lfany') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?8lfany#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icon-file-news {
  &:before {
    content: unicode($icon-file-news);     
    color: #fff;
  }
}
.icon-file-document {
  &:before {
    content: unicode($icon-file-document);     
    color: #fff;
  }
}
.icon-xla-combined-shape {
  &:before {
    content: unicode($icon-xla-combined-shape); 
  }
}
.icon-xla-fb {
  &:before {
    content: unicode($icon-xla-fb); 
  }
}
.icon-xla-insta {
  &:before {
    content: unicode($icon-xla-insta); 
  }
}
.icon-xla-oval-copy {
  &:before {
    content: unicode($icon-xla-oval-copy); 
  }
}
.icon-xla-pinsterest {
  &:before {
    content: unicode($icon-xla-pinsterest); 
  }
}
.icon-xla-right-arrow {
  &:before {
    content: unicode($icon-xla-right-arrow); 
  }
}
.icon-xla-left-arrow {
  &:before {
    content: unicode($icon-xla-left-arrow); 
  }
}
.icon-xla-left-arrow-carousel {
  &:before {
    content: unicode($icon-xla-left-arrow-carousel); 
  }
}
.icon-xla-right-arrow-carousel {
  &:before {
    content: unicode($icon-xla-right-arrow-carousel); 
  }
}
.icon-right-arrow {
  &:before {
    content: unicode($icon-right-arrow); 
  }
}
.icon-close {
  &:before {
    content: unicode($icon-close); 
  }
}
.icon-zoom {
  &:before {
    content: unicode($icon-zoom); 
  }
}
.icon-browse {
  &:before {
    content: unicode($icon-browse); 
  }
}
.icon-remove {
  &:before {
    content: unicode($icon-remove); 
  }
}
.icon-xla-twitter {
  &:before {
    content: unicode($icon-xla-twitter); 
  }
}
.icon-xla-google-plus {
  &:before {
    content: unicode($icon-xla-google-plus); 
  }
}
.icon-zip {
  &:before {
    content: unicode($icon-zip); 
  }
}
.icon-ppt {
  &:before {
    content: unicode($icon-ppt); 
  }
}
.icon-jpeg {
  &:before {
    content: unicode($icon-jpeg); 
  }
}
.icon-doc {
  &:before {
    content: unicode($icon-doc); 
  }
}
.icon-pdf {
  &:before {
    content: unicode($icon-pdf); 
  }
}

