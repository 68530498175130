.bg-attente {
  width: 100vw;
  height: 100vh;
  background-image: url(/frontend/images/bg-attente.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .card {
    width: 360px;
    height: 320px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    h6 {
      font-family: MaisonNeue, sans-serif;
      font-size: 24px;
      font-weight: 100;
      color: $white; }
    p {
      color: $white; } } }

@include media-breakpoint-down(sm) {
  .card {
    width: 300px;
    height: 250px; } }
