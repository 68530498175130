/* carousel */

.carousel {
  .carousel-control-prev {
    opacity: 1;
    .icon-xla-left-arrow {
      font-size: 30px;
      color: $white; } }

  .carousel-control-next {
    opacity: 1;
    .icon-xla-right-arrow {
      font-size: 30px;
      color: $white; } }

  .control-bottom {
    cursor: pointer;
    position: absolute;
    top: 93%;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    text-align: center;
    z-index: 100; } }


.carousel-item {
  height: calc(100vh - 72px);
  background: no-repeat center center scroll;
  background-size: cover; }

.carousel-indicators {
  justify-content: flex-end;
  bottom: 0;
  left: 6%;
  width: 670px;
  margin-right: 0;
  margin-left: 0;
  li {
    width: 20px;
    height: 20px;
    text-indent: 1px;
    border-bottom: $primary 1px solid;
    background-color: transparent;
    margin-bottom: 135px;
    &.active {
      border-bottom: none;
      height: 0; }
    &:hover {
      cursor: pointer; } } }

.carousel-caption {
  text-shadow: 2px 2px #000;
  left: 6%;
  width: 670px;
  text-align: right;
  margin-bottom: 150px;
  .text-carousel {
    position: absolute;
    right: 0;
    top: -30px;
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      background-color: $secondary;
      left: -75px;
      bottom: 8px;
      width: 68px; } } }

/* slider */

.slick-slider-center {
  .icon-xla-right-arrow-carousel {
    position: absolute;
    font-size: 22px;
    right: 32.5%;
    top: 32.5%;
    z-index: 1;
    cursor: pointer;
    color: $white;
    &:hover {
      color: $secondary; } }

  .icon-xla-left-arrow-carousel {
    position: absolute;
    font-size: 22px;
    left: 32.5%;
    top: 32.5%;
    z-index: 1;
    cursor: pointer;
    color: $white;
    &:hover {
      color: $secondary; } }

  .slick-slide {
    filter: grayscale(100%);
    transition: all 0.5s ease;
    opacity: 0.2; }

  .slick-center {
    filter: grayscale(0%);
    opacity: 1; } }

.slick-slider-collection {
  .collection {
    opacity: 0;
    transition: opacity 0.5s ease;

    hr {
      border-color: $primary; } }

  .slick-center {
    .collection {
      opacity: 1; } } }

.slick-dots {
  bottom: 5px;
  li {
    button {
      font-size: 24px;
      &:before {
        color: #ffffff;
        font-size: 24px;
        opacity: 1; } }
    &.slick-active {
      button {
        &:before {
          color: $secondary; } } } } }

.img-100 {
  img {
    width: 100%; } }

@include media-breakpoint-down(md) {
   .carousel {
    .carousel-caption {
      width: 260px;
      margin-bottom: 20px;
      .text-carousel {
        &:before {
          left: -25px;
          bottom: 8px;
          width: 20px; } } }

    .carousel-item {
      height: calc(100vh - 55px); }

    .carousel-indicators {
      display: none; }

    .icon-xla-right-arrow {
      &:before {
        display: none; } }

    .icon-xla-left-arrow {
      &:before {
        display: none; } } } }


/* Slider */
.slick-loading .slick-list {
  background: none; }
